import { Container, Row } from 'react-bootstrap';

export default function Unauthorized() {
  return (
    <Container className="margin-auto">
      <Row>
        <h1>Welcome to Warranty Services by Amazon Tools</h1>
      </Row>

      <Row className="d-flex justify-content-center align-items-center">
        <div className="inline-block align-middle font-weight-normal lead">
          You are not authorized to access this web application. Please contact the&nbsp;
          <a href="https://w.amazon.com/bin/view/WarrantyServices/" target="_blank" rel="noopener noreferrer">
            WSA team
          </a>
        </div>
      </Row>
    </Container>
  );
}
