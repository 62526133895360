import {CountryCodeToMarketplaceIdMapContext} from '@amzn/country-code-to-marketplace-id-map-type-script-client/dist/countryCodeToMarketplaceIdMap';
import {createOfflineMarketplaceClient} from "../acsClient/offlineMarketPlaceClient";
import {createOfflineCountryCodeClient} from "../acsClient/offlineCountryCodeClient";
import MarketplaceConfigQueries from "@amzn/marketplace-offline-config/dist/acs_MarketplaceConfig/queries.json";
import {OfflineConfigManager} from "@amzn/amazon-config-store-type-script-offline-adapter";
import {MarketplaceConfigContext} from '@amzn/marketplace-config-type-script-client/dist/marketplaceConfig';

export interface Marketplace {
    obfuscated_marketplace_id: string;
    country_code: string;
}

const RETAIL_FAMILY = 'retail';

/**
 * Populates the MARKETPLACE_CONFIG array with MarketplaceMapping objects
 * for the marketplaces retrieved from the ACS Marketplace Config that are in the Retail marketplace.
 */
export function fetchMarketplaces() {
    const marketplaceList: Marketplace[] = [];
    const offlineMarketplaceQueriesJson = JSON.stringify(MarketplaceConfigQueries.queries);
    const jsonMarketplaceObject = JSON.parse(offlineMarketplaceQueriesJson);

    const configManager = new OfflineConfigManager();
    const offlineMarketPlaceClient = createOfflineMarketplaceClient(configManager);
    const offlineCountrycodeClient = createOfflineCountryCodeClient(configManager);

     Object.values(jsonMarketplaceObject).forEach((marketplace: any) =>
         addMarketplaceToList(marketplace,marketplaceList,offlineMarketPlaceClient,offlineCountrycodeClient));

    marketplaceList.sort((a, b) => (a.country_code > b.country_code ? 1 : b.country_code > a.country_code ? -1 : 0))
    return marketplaceList
}

 function addMarketplaceToList (marketplace: any, marketplaceConfig: Marketplace[],offlineMarketPlaceClient: any,
                                      offlineCountrycodeClient: any) {
    const marketplaceId = marketplace.keys.acs_marketplace_id;
    const countryCode = offlineMarketPlaceClient.getMarketplace(
        new MarketplaceConfigContext(marketplaceId)
    )?.defaultCountryCode || "";
    const retailObfuscatedId = offlineCountrycodeClient.getObfuscatedMarketplaceId(
        new CountryCodeToMarketplaceIdMapContext(RETAIL_FAMILY, countryCode.toUpperCase())
    );

    if (marketplaceId === retailObfuscatedId) {
        marketplaceConfig.push({
            obfuscated_marketplace_id: marketplaceId,
            country_code: countryCode,
        });
    }
}