import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css'
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import store from './app/store'
import {Provider} from 'react-redux'
import {Route, Routes} from "react-router";
import Unauthorized from "./components/Unauthorized";
import DeviceWarrantySearchPage from "./containers/DeviceWarrantySearchPage";
import ExtendedWarrantySearchPage from "./containers/ExtendedWarrantySearchPage";
import OncallOperationsPage from "./containers/OncallOperationsPage";
import HomePage from "./containers/HomePage";
import axios from "axios";
import {getMidwayJwtToken} from './auth/MidwayJwtToken';

// Add Authorization header with midway token to all axios calls
axios.interceptors.request.use(async (config) => {
  const jwtToken = await getMidwayJwtToken();
  config.headers!.Authorization = `Bearer ${jwtToken}`
  return config;
});

(async () => {
  // To ensure sure user has valid Midway token before rendering
  await getMidwayJwtToken();

  ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App/>}>
              <Route index element={<HomePage/>}/>
              <Route path="unauthorized" element={<Unauthorized/>}/>
              <Route path="search/deviceWarranty" element={<DeviceWarrantySearchPage/>}/>
              <Route path="search/extendedWarranty" element={<ExtendedWarrantySearchPage/>}/>
              <Route path="search/oncallOperations" element={<OncallOperationsPage/>}/>
              <Route
                  path="*"
                  element={
                    <main style={{padding: "1rem"}}>
                      <p>There's nothing here!</p>
                    </main>
                  }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>,
      document.getElementById('root')
  );
})();
