import React, { useState } from 'react';
import { ButtonGroup, Card, Container, ToggleButton, Row, Col } from 'react-bootstrap';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import JSONText from './JSONText';

const viewerOptions = [
  { name: 'Tabular', value: 'tabular' },
  { name: 'JSON', value: 'json' }
];

interface APIResultViewerProps {
  title: string;
  data: object[];
  keyField: string;
  tableHeaderComponent?: () => JSX.Element;
  columns: ColumnDescription[];
}

/**
 * Component to show the API response in a tabular view or json view
 */
export default function APIResultViewer({
  title,
  data,
  keyField,
  columns,
  tableHeaderComponent
}: APIResultViewerProps) {
  const [radioValue, setRadioValue] = useState('tabular');

  const jsonView = () => {
    return (
      <Card bg="light">
        <Card.Body className="text-start">
          <JSONText data={data} />
        </Card.Body>
      </Card>
    );
  };

  const tabularView = () => {
    return (
      <React.Fragment>
        <Container>
          {tableHeaderComponent ? <React.Fragment>{tableHeaderComponent()}</React.Fragment> : <React.Fragment />}
          <BootstrapTable
            wrapperClasses="table-responsive"
            bootstrap4
            striped
            hover
            keyField={keyField}
            data={data}
            columns={columns}
          />
        </Container>
      </React.Fragment>
    );
  };

  return (
    <Container className="mt-4" fluid>
      <Row className="mt-3">
        <h3>{title}</h3>
      </Row>
      <Row>
        <Col>
          <ButtonGroup>
            {viewerOptions.map((radio, idx) => (
              <ToggleButton
                key={`${title}-${idx}`}
                id={`${title}-radio-${idx}`}
                type="radio"
                variant="outline-secondary"
                name={`${title}-radio`}
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Col>
      </Row>
      <Row className="mt-3">{radioValue === 'json' ? jsonView() : tabularView()}</Row>
    </Container>
  );
}
