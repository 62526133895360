export function convertUnixTimestampToDateString(timestamp: number | undefined): string {
  if (timestamp === undefined) {
    return '';
  }
  return new Date(timestamp * 1000).toISOString();
}

export function capitalizeAndTrim(str: string) {
  return str.toUpperCase().trim();
}

export function getQueryParamAsStringOrUseFallback(
  searchParams: URLSearchParams,
  queryParam: string,
  fallback: string
): string {
  const queryParamValue: string | null = searchParams.get(queryParam);
  if (queryParamValue === null || queryParamValue!.length === 0) {
    return fallback;
  }
  return queryParamValue!;
}

export function getQueryParamAsNumberOrUseFallback(
  searchParams: URLSearchParams,
  queryParam: string,
  fallback: number
): number {
  const queryParamValue: number = parseInt(searchParams.get(queryParam) || '');
  if (isNaN(queryParamValue)) {
    return fallback;
  }
  return queryParamValue;
}

export const DORMANT_WARRANTY_EXCEPTION_NAME_STRING = "DormantWarrantyException";
