import {
    ClientStage,
    OfflineClientSettings,
    OfflineConfigManager
} from "@amzn/amazon-config-store-type-script-offline-adapter";
import {CountryCodeToMarketplaceIdMapClientProvider} from '@amzn/country-code-to-marketplace-id-map-type-script-client/dist/countryCodeToMarketplaceIdMap';
import CountryCodeToMPConfigQueries from '@amzn/country-code-to-marketplace-id-map-offline-config/dist/acs_CountryCodeToMarketplaceIdMap/queries.json';
import CountryCodeToMPConfigDoc from '@amzn/country-code-to-marketplace-id-map-offline-config/dist/acs_CountryCodeToMarketplaceIdMap/documents.json';
import CountryCodeToMPConfigHQ from '@amzn/country-code-to-marketplace-id-map-offline-config/dist/devo/acs_CountryCodeToMarketplaceIdMap/hierarchical_queries.json';

/**
 * Creates an offline country code to marketplace ID map client.
 * @param configManager - The OfflineConfigManager instance.
 * @returns The offline country code to marketplace ID map client.
 */
export function createOfflineCountryCodeClient(configManager: OfflineConfigManager): any {
    return configManager.createClient(
        new CountryCodeToMarketplaceIdMapClientProvider(),
        {
            load: () => ({
                documents: CountryCodeToMPConfigDoc.documents,
                exactQueries: CountryCodeToMPConfigQueries.queries,
                hierarchicalFallbackQueries: CountryCodeToMPConfigHQ.queries
            })
        },
        new OfflineClientSettings({ stage: ClientStage.SANDBOX })
    );
}

