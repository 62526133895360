import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { useAppSelector } from '../app/hooks';

export default function NavBar() {
  const { userAlias } = useAppSelector((state) => state.user);

  return (
    <Navbar bg="dark" expand="lg" variant="dark" className="text-light">
      <Container>
        <Navbar.Brand href="#home">WSATools</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="Warranty Search" id="basic-nav-dropdown">
              <NavDropdown.Item href="/search/devicewarranty">Device Warranty Search</NavDropdown.Item>
              <NavDropdown.Item href="/search/extendedwarranty">Extended Warranty Search</NavDropdown.Item>
              <NavDropdown.Item href="/search/oncallOperations">Oncall Operations</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        <Container className="text-end">Welcome {userAlias}</Container>
      </Container>
    </Navbar>
  );
}
