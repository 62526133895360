import {
    ClientStage,
    OfflineClientSettings,
    OfflineConfigManager
} from "@amzn/amazon-config-store-type-script-offline-adapter";
import {MarketplaceConfigClientProvider} from '@amzn/marketplace-config-type-script-client/dist/marketplaceConfig';
import MarketplaceConfigDoc from "@amzn/marketplace-offline-config/dist/acs_MarketplaceConfig/documents.json";
import MarketplaceConfigQueries from "@amzn/marketplace-offline-config/dist/acs_MarketplaceConfig/queries.json";
import MarketplaceConfigHQ from "@amzn/marketplace-offline-config/dist/acs_MarketplaceConfig/hierarchical_queries.json";

/**
 * Creates an offline marketplace client.
 * @param configManager - The OfflineConfigManager instance.
 * @returns The offline marketplace client.
 */
export function createOfflineMarketplaceClient(configManager: OfflineConfigManager): any {
    return configManager.createClient(
        new MarketplaceConfigClientProvider(),
        {
            load: () => ({
                documents: MarketplaceConfigDoc.documents,
                exactQueries: MarketplaceConfigQueries.queries,
                hierarchicalFallbackQueries: MarketplaceConfigHQ.queries
            })
        },
        new OfflineClientSettings({ stage: ClientStage.SANDBOX })
    );
}