import React, { useEffect, useState } from 'react';
import APIResultViewer from '../components/APIResultViewer';
import { Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { ColumnDescription } from 'react-bootstrap-table-next';
import {
  capitalizeAndTrim,
  DORMANT_WARRANTY_EXCEPTION_NAME_STRING,
  getQueryParamAsStringOrUseFallback
} from '../constants/utils';
import {
    backfillLimitedWarrantyV2,
    ClaimOption,
    ClaimTypeCode,
    getDeviceTypeForDsn,
    getWarrantiesByDevice, getWarrantyClaimCode,
    transferLimitedWarrantyForCanceledClaim
} from '../api/WsaToolsFacade';
import { addNotification, clearNotifications, Severity } from '../actions/notificationsSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { dispatchRequestErrorNotification, RETRY_MESSAGES, SUCCESS_MESSAGES } from '../constants/notificationMessages';
import JSONText from '../components/JSONText';
import { QuestionCircle } from 'react-bootstrap-icons';
import { useSearchParams } from 'react-router-dom';
import MarketplaceSelector from '../components/MarketplaceSelector';

const ACTIVE_WARRANTIES_COLUMNS: ColumnDescription[] = [
  { dataField: 'id', text: 'Warranty Id', sort: true },
  { dataField: 'type', text: 'Warranty Type', sort: true },
  { dataField: 'startDate', text: 'Start Date', sort: true },
  { dataField: 'expirationDate', text: 'Expiration Date', sort: true }
];

const DANGER_TEXT_WITH_BACKGROUND = 'bg-danger text-white p-2 align-middle';
const SUCCESS_TEXT_WITH_BACKGROUND = 'bg-success text-white p-2 align-middle';
const WARNING_TEXT_WITH_BACKGROUND = 'bg-warning text-black p-2 align-middle';
const CLAIM_HISTORY_COLUMNS: ColumnDescription[] = [
  { dataField: 'warrantyClaimId', text: 'Claim Id', sort: true },
  { dataField: 'associatedWarrantyId', text: 'Associated Warranty Id', sort: true },
  { dataField: 'isCancelled', text: 'Claim Status', sort: false,
    formatter: (isCancelled) => {
      return (
          <div className={isCancelled ? DANGER_TEXT_WITH_BACKGROUND : SUCCESS_TEXT_WITH_BACKGROUND}>
            {isCancelled ? 'Claim Cancelled' : 'Claim Active'}
          </div>
      );
    }
    },
  { dataField: 'warrantyClaimTypeCode', text: 'Claim Type', sort: true },
  {
    dataField: 'claimDate',
    text: 'Claim Date',
    sort: true,
    style: { minWidth: '250px' }
  },
  { dataField: 'claimCustomerId', text: 'Claim Customer Id', sort: true },
  { dataField: 'claimDevice', text: 'Claim Device', sort: true, formatter: (data) => <JSONText data={data} /> },
  {
    dataField: 'claimDeviceOrderUnitRecord',
    text: 'Claim Device Order Unit Record',
    sort: true,
    formatter: (data) => <JSONText data={data} />
  },
  { dataField: 'originalDevice', text: 'Original Device', sort: true, formatter: (data) => <JSONText data={data} /> },
  {
    dataField: 'originalDeviceOrderUnitRecord',
    text: 'Original Device Order Unit Record',
    sort: true,
    formatter: (data) => <JSONText data={data} />
  },
  {
    dataField: 'replacementDevice',
    text: 'Replacement Device',
    sort: true,
    formatter: (data) => <JSONText data={data} />
  },
  {
    dataField: 'replacementDeviceOrderUnitRecord',
    text: 'Replacement Device Order Unit Record',
    sort: true,
    formatter: (data) => <JSONText data={data} />
  }
];

const WARRANTY_CLAIM_OPTION_COLUMNS: ColumnDescription[] = [
  { dataField: 'name', text: 'Name', sort: true },
  { dataField: 'replacementType', text: 'Replacement Type', sort: true },
  { dataField: 'replacementCharge', text: 'Replacement Charge', sort: true },
  { dataField: 'replacementEligibility', text: 'Replacement Eligibility', sort: true },
  { dataField: 'isException', text: 'Is Exception?', sort: true }
];

export interface DeviceActiveWarranty {
  type: string;
  id: string;
  customerId: string;
  startDate: Date;
  expirationDate: Date;
  associatedDevice: DeviceIdentifier;
}

export interface DeviceIdentifier {
  serialNumber: string;
  type: string;
}

export interface GetWarrantyClaimCodes {
  warrantyClaimCode: ClaimOption | null;
  overrideWarrantyClaimCodes: ClaimOption[];
  apiCallStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
}

export interface DeviceWarrantyClaim {
  warrantyClaimId: string;
  associatedWarrantyId: string;
  warrantyClaimTypeCode: string;
  claimDate: Date;
  claimDevice: DeviceIdentifier;
  replacementDevice: DeviceIdentifier;
  isCancelled: boolean;
}

export interface GetWarrantyAndWarrantyClaims {
  activeWarranties: DeviceActiveWarranty[];
  claimHistory: DeviceWarrantyClaim[];
  apiCallStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  dormantWarranty: boolean;
}

const MANUFACTURER_WARRANTY_TYPES = ['LIMITED_WARRANTY', 'WORRY_FREE_OFFERING'];

enum BindingStatus {
  PREVIOUSLY_BOUND = 'PREVIOUSLY BOUND',
  CURRENTLY_BOUND = 'CURRENTLY BOUND'
}

const compareClaimDatesInDescOrder = (claimA: DeviceWarrantyClaim, claimB: DeviceWarrantyClaim): -1 | 0 | 1 => {
  if (claimA.claimDate < claimB.claimDate) {
    return 1;
  }
  if (claimA.claimDate > claimB.claimDate) {
    return -1;
  }
  return 0;
};

const DEVICE_WARRANTY_PAGE_WIKI = 'https://w.amazon.com/bin/view/WarrantyServices/WSATools/#HDeviceWarrantySearchPage';
const DEVICE_SERIAL_NUMBER_QUERY_PARAM = 'deviceSerialNumber';
const DEVICE_TYPE_QUERY_PARAM = 'deviceType';
const CUSTOMER_ID_QUERY_PARAM = 'customerId';
const MARKETPLACE_ID_QUERY_PARAM = 'marketplaceId';
const CLAIM_TYPE_QUERY_PARAM = 'claimType';


export default function DeviceWarrantySearchPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { userAlias } = useAppSelector((state) => state.user);

  const [showBackfillLwModal, setShowBackfillLwModal] = useState(false);
  const handleCloseBackfillLwModal = () => setShowBackfillLwModal(false);

  const [showCancelClaimModal, setShowCancelClaimModal] = useState(false);
  const handleCloseCancelClaimModal = () => setShowCancelClaimModal(false);

  const [deviceSerialNumber, setDeviceSerialNumber] = useState<string>(
    getQueryParamAsStringOrUseFallback(searchParams, DEVICE_SERIAL_NUMBER_QUERY_PARAM, '').toUpperCase()
  );
  const [deviceType, setDeviceType] = useState<string>(
    getQueryParamAsStringOrUseFallback(searchParams, DEVICE_TYPE_QUERY_PARAM, '').toUpperCase()
  );
  const [customerId, setCustomerId] = useState<string>(
    getQueryParamAsStringOrUseFallback(searchParams, CUSTOMER_ID_QUERY_PARAM, 'ATVPDKIKX0DER').toUpperCase()
  );
  const [marketplaceId, setMarketplaceId] = useState<string>(
    getQueryParamAsStringOrUseFallback(searchParams, MARKETPLACE_ID_QUERY_PARAM, 'ATVPDKIKX0DER').toUpperCase()
  );

  let claimTypeQueryParamStr = getQueryParamAsStringOrUseFallback(
    searchParams,
    CLAIM_TYPE_QUERY_PARAM,
    'MANUFACTURER_INDUCED_DEFECT'
  ).toUpperCase();
  // Need to make sure we get valid ClaimTypeCode enum value and not a random string
  claimTypeQueryParamStr =
    claimTypeQueryParamStr in ClaimTypeCode ? claimTypeQueryParamStr : 'MANUFACTURER_INDUCED_DEFECT';
  const [claimTypeCode, setClaimTypeCode] = useState<ClaimTypeCode>(
    ClaimTypeCode[claimTypeQueryParamStr as keyof typeof ClaimTypeCode]
  );



  const [warrantyClaimCodes, setWarrantyClaimCodes] = useState<GetWarrantyClaimCodes>({
      warrantyClaimCode: null,
      overrideWarrantyClaimCodes: [],
      apiCallStatus: 'idle'});

  const generateWarrantyClaimOptions = (warrantyClaimCodes: GetWarrantyClaimCodes): ClaimOption[] => {
    let claimOptions: ClaimOption[] = [];
    if (warrantyClaimCodes.warrantyClaimCode !== null) {
      claimOptions.push(warrantyClaimCodes.warrantyClaimCode);
    }
    claimOptions = claimOptions.concat(warrantyClaimCodes.overrideWarrantyClaimCodes);
    return claimOptions;
  }

  const [warrantyAndWarrantyClaims, setWarrantyAndWarrantyClaims] = useState<GetWarrantyAndWarrantyClaims>({
    activeWarranties: [],
    claimHistory: [],
    apiCallStatus: 'idle',
    dormantWarranty: false
  });
  const [mostRecentClaim, setMostRecentClaim] = useState<DeviceWarrantyClaim | undefined>(undefined);

  useEffect(() => {
    if (!deviceSerialNumber) {
      return;
    }

    const deviceSerialNumberCopy = deviceSerialNumber.slice();
    getDeviceTypeForDsn({ deviceSerialNumber: deviceSerialNumberCopy})
      .then(({ status, data }) => {
        if (status === 200 && data.deviceSerialNumber === deviceSerialNumberCopy) {
          // Only overwrite the deviceType field if "getDeviceTypeForDsn" returns a valid value
          // And the DSN hasn't changed since the request was made
          setDeviceType(data.deviceType);
        }
      })
      // Best effort to get a deviceType for a given dsn, ignore errors
      .catch((_) => {});
  }, [deviceSerialNumber]);

  useEffect(() => {
    if (warrantyAndWarrantyClaims.claimHistory.length === 0) {
      return setMostRecentClaim(undefined);
    }

    // Assumption: Only the most recent claim can be cancelled. There shouldn't be
    // a chain of claims that need to be cancelled. If there is, the expectation is that
    // WSA will be cut a ticket and on-call will handle this since this can be a larger system issue.
    // NOTE: We can cancel multiple claims using the `isCancelled` flag to determine which device to rollback to.
    setMostRecentClaim(warrantyAndWarrantyClaims.claimHistory.sort(compareClaimDatesInDescOrder)[0]);
  }, [warrantyAndWarrantyClaims]);

  const isLoading = () => {
    return warrantyAndWarrantyClaims.apiCallStatus === 'loading' || warrantyClaimCodes.apiCallStatus === 'loading';
  };

  const isFormValid = () => {
    return !!deviceSerialNumber && !!deviceType && !!marketplaceId;
  };

  const handleQuery = async () => {
    if (!isFormValid() || isLoading()) {
      return;
    }

    setSearchParams(
      {
        [DEVICE_SERIAL_NUMBER_QUERY_PARAM]: deviceSerialNumber,
        [DEVICE_TYPE_QUERY_PARAM]: deviceType,
        [MARKETPLACE_ID_QUERY_PARAM]: marketplaceId,
        [CLAIM_TYPE_QUERY_PARAM]: claimTypeCode
      },
      { replace: false }
    );

    setWarrantyAndWarrantyClaims({
      ...warrantyAndWarrantyClaims,
      apiCallStatus: 'loading'
    });
    setWarrantyClaimCodes({
      ...warrantyClaimCodes,
      apiCallStatus: 'loading'
    });
    dispatch(clearNotifications());

    const getWarrantiesByDevicePromise = getWarrantiesByDevice({
      deviceSerialNumber: deviceSerialNumber,
      deviceType: deviceType,
      includeClaims: true,
      includeUnboundEW: false,
      includePreviouslyBoundWarranties: true
    });

    const getWarrantyClaimCodePromise = getWarrantyClaimCode({
        device: {
            serialNumber: deviceSerialNumber,
            type: deviceType
        },
        claimTypeCode: claimTypeCode,
        claimMarketplaceId: marketplaceId,
        customerId: customerId
    });

    Promise.all([getWarrantiesByDevicePromise, getWarrantyClaimCodePromise])
      .then((responses) => {
        const [getWarrantiesByDeviceResponse, getWarrantyClaimCodesResponse] = responses;
        if (getWarrantiesByDeviceResponse.status === 200) {
          setWarrantyAndWarrantyClaims({
            activeWarranties: getWarrantiesByDeviceResponse.data.warrantyList,
            claimHistory: getWarrantiesByDeviceResponse.data.warrantyList
              .flatMap(({ id, claimHistory }: any) => {
                if (claimHistory) {
                  return claimHistory.map((claim: any) => ({ ...claim, associatedWarrantyId: id }));
                }
                return [];
              })
              .sort(compareClaimDatesInDescOrder),
            apiCallStatus: 'succeeded',
            dormantWarranty: false
          });
        } else {
          setWarrantyAndWarrantyClaims({
            activeWarranties: [],
            claimHistory: [],
            apiCallStatus: 'failed',
            dormantWarranty: getWarrantiesByDeviceResponse.headers["x-amzn-errortype"] === DORMANT_WARRANTY_EXCEPTION_NAME_STRING
          });
          dispatchRequestErrorNotification(
            getWarrantiesByDeviceResponse.status,
            getWarrantiesByDeviceResponse.data.toString(),
              getWarrantiesByDeviceResponse.headers
          );
        }

        if (getWarrantyClaimCodesResponse.status === 200) {
          setWarrantyClaimCodes({
            warrantyClaimCode: getWarrantyClaimCodesResponse.data.warrantyClaimCode,
            overrideWarrantyClaimCodes: getWarrantyClaimCodesResponse.data.overrideWarrantyClaimCodes,
            apiCallStatus: 'succeeded'
          });
        } else {
          setWarrantyClaimCodes({
            warrantyClaimCode: null,
            overrideWarrantyClaimCodes: [],
            apiCallStatus: 'failed' });
          dispatchRequestErrorNotification(
             getWarrantyClaimCodesResponse.status,
             getWarrantyClaimCodesResponse.data.toString(),
              getWarrantiesByDeviceResponse.headers
          );
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(addNotification({ message: RETRY_MESSAGES.DEFAULT, severity: Severity.ERROR }));
        setWarrantyAndWarrantyClaims({
          activeWarranties: [],
          claimHistory: [],
          apiCallStatus: 'failed',
          dormantWarranty: false
        });
        setWarrantyClaimCodes({
          warrantyClaimCode: null,
          overrideWarrantyClaimCodes: [],
          apiCallStatus: 'failed'
        });
      });
  };

  const handleBackfillLw = async () => {
    backfillLimitedWarrantyV2({
      deviceSerialNumber: deviceSerialNumber,
      deviceType: deviceType
    })
      .then(({ status, data, headers }) => {
        if (status === 200) {
          dispatch(addNotification({ message: SUCCESS_MESSAGES.BACKFILL_LW, severity: Severity.SUCCESS }));
        } else {
          dispatchRequestErrorNotification(status, data, headers);
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(addNotification({ message: RETRY_MESSAGES.DEFAULT, severity: Severity.ERROR }));
      });
    handleCloseBackfillLwModal();
  };

  const handleCancelClaim = async () => {
    if (mostRecentClaim === undefined) {
      return;
    }

    transferLimitedWarrantyForCanceledClaim({
      warrantyId: mostRecentClaim.associatedWarrantyId,
      deviceSerialNumber: mostRecentClaim.claimDevice.serialNumber,
      deviceType: mostRecentClaim.claimDevice.type
    })
      .then(({ status, data, headers }) => {
        if (status === 200) {
          dispatch(
            addNotification({ message: SUCCESS_MESSAGES.TRANSFER_LW_FOR_CANCELED_CLAIM, severity: Severity.SUCCESS })
          );
        } else {
          dispatchRequestErrorNotification(status, data, headers);
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(addNotification({ message: RETRY_MESSAGES.DEFAULT, severity: Severity.ERROR }));
      });
    handleCloseCancelClaimModal();
  };

  const handleFormInputKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      handleQuery();
    }
  };

  const handleOnReset = () => {
    setDeviceSerialNumber('');
    setDeviceType('');
    setMarketplaceId('ATVPDKIKX0DER');
    setClaimTypeCode(ClaimTypeCode.MANUFACTURER_INDUCED_DEFECT);
    setWarrantyAndWarrantyClaims({
      activeWarranties: [],
      claimHistory: [],
      apiCallStatus: 'idle',
      dormantWarranty: false
    });
    setWarrantyClaimCodes({
      warrantyClaimCode: null,
      overrideWarrantyClaimCodes: [],
      apiCallStatus: 'idle'
    });
    dispatch(clearNotifications());
    setSearchParams({});
  };

  const renderQueryResultViewer = () => {
    if (warrantyAndWarrantyClaims.apiCallStatus === 'succeeded' || warrantyClaimCodes.apiCallStatus === 'succeeded') {
      const hasActiveLw = warrantyAndWarrantyClaims.activeWarranties.some(({ type }) =>
        MANUFACTURER_WARRANTY_TYPES.includes(type)
      );
      const hasWarrantiesActivelyBoundToDevice = warrantyAndWarrantyClaims.activeWarranties.some(
        ({ associatedDevice }) =>
          associatedDevice &&
          associatedDevice.serialNumber === deviceSerialNumber &&
          associatedDevice.type === deviceType
      );

      const isDormantWarranty = warrantyAndWarrantyClaims.dormantWarranty;

      return (
        <React.Fragment>
          <hr className="ms-3 me-3" />

          {hasWarrantiesActivelyBoundToDevice || isDormantWarranty ? (
            <APIResultViewer
              title="Warranty Claim Options"
              data={generateWarrantyClaimOptions(warrantyClaimCodes)}
              keyField={'name'}
              columns={WARRANTY_CLAIM_OPTION_COLUMNS}
            />
          ) : (
            <React.Fragment>
              <h3>Warranty Claim Options</h3>
              <span>No warranties actively bound to queried device. </span>
            </React.Fragment>
          )}

          <APIResultViewer
            title="Associated Warranties"
            data={warrantyAndWarrantyClaims.activeWarranties}
            keyField={'id'}
            columns={[
              ...ACTIVE_WARRANTIES_COLUMNS,
              {
                dataField: 'associatedDevice',
                text: 'Bound Device',
                formatter: (associatedDevice, { claimHistory }) => {
                  const hasClaim = claimHistory && claimHistory.length > 0;
                  return hasClaim &&
                    (!associatedDevice ||
                      (associatedDevice.serialNumber === undefined && associatedDevice.type === undefined)) ? (
                    <span>Waiting for replacement device to be fulfilled.</span>
                  ) : (
                    <JSONText data={associatedDevice} />
                  );
                }
              },
              {
                dataField: 'associatedDevice',
                text: 'Binding Status',
                formatter: (associatedDevice, { claimHistory }) => {
                  const hasClaim = claimHistory && claimHistory.length > 0;
                  if (
                    hasClaim &&
                    (!associatedDevice ||
                      (associatedDevice.serialNumber === undefined && associatedDevice.type === undefined))
                  ) {
                    // No device bound, waiting for replacement
                    return <React.Fragment />;
                  }

                  const bindingStatus =
                    associatedDevice &&
                    associatedDevice.serialNumber === deviceSerialNumber &&
                    associatedDevice.type === deviceType
                      ? BindingStatus.CURRENTLY_BOUND
                      : BindingStatus.PREVIOUSLY_BOUND;
                  return (
                    <div className={bindingStatus === BindingStatus.CURRENTLY_BOUND ? SUCCESS_TEXT_WITH_BACKGROUND : WARNING_TEXT_WITH_BACKGROUND}>
                      {bindingStatus}
                    </div>
                  );
                }
              }
            ]}
            tableHeaderComponent={() => {
              return hasActiveLw ? (
                <React.Fragment />
              ) : (
                <Button className="mb-3" onClick={() => setShowBackfillLwModal(true)}>
                  Backfill Limited Warranty
                </Button>
              );
            }}
          />
          <APIResultViewer
            title="Claim History"
            keyField={'warrantyClaimId'}
            data={warrantyAndWarrantyClaims.claimHistory}
            columns={CLAIM_HISTORY_COLUMNS}
            tableHeaderComponent={() => {
              if (warrantyAndWarrantyClaims.claimHistory.length === 0) {
                return <React.Fragment />;
              }

              return (
                <Button
                  className={mostRecentClaim !== undefined && mostRecentClaim.isCancelled ? 'mb-3 btn-warning' : 'mb-3 btn-primary'}
                  disabled={mostRecentClaim === undefined || mostRecentClaim.isCancelled}
                  onClick={() => setShowCancelClaimModal(true)}
                >
                  {mostRecentClaim !== undefined && mostRecentClaim.isCancelled ? 'Most Recent Claim Already Cancelled' : 'Cancel Most Recent Claim'}
                </Button>
              );
            }}
          />
        </React.Fragment>
      );
    }
    return <React.Fragment />;
  };

  return (
    <div>
      <Container fluid={true}>
        <Row>
          <h2>
            DEVICE WARRANTY SEARCH &nbsp;
            <a href={DEVICE_WARRANTY_PAGE_WIKI} target="blank" className="d-inline-flex align-bottom mb-1">
              <QuestionCircle />
            </a>
          </h2>
        </Row>
        <Row className="m-auto justify-content-center">
          <Col sm={8}>
            <Container className="text-start h-fill">
              <Form>
                <Row>
                  <Col className="m-auto" sm={4}>
                    <Form.Group className="mb-3" controlId="DeviceWarrantySearchForm">
                      <Form.Label>Device Serial Number: </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={deviceSerialNumber}
                        onKeyPress={handleFormInputKeyPress}
                        onChange={(e) => {
                          setDeviceSerialNumber(capitalizeAndTrim(e.target.value));
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="DeviceWarrantySearchForm">
                      <Form.Label>Device Type: </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={deviceType}
                        onKeyPress={handleFormInputKeyPress}
                        onChange={(e) => {
                          setDeviceType(capitalizeAndTrim(e.target.value));
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="DeviceWarrantySearchForm">
                      <Form.Label className="text-left">(Optional) Customer Id: </Form.Label>
                      <Form.Control
                        type="text"
                        value={customerId}
                        onKeyPress={handleFormInputKeyPress}
                        onChange={(e) => {
                          setCustomerId(capitalizeAndTrim(e.target.value));
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="DeviceWarrantySearchForm">
                      <Form.Label className="text-left">Marketplace Id: </Form.Label>
                      <MarketplaceSelector
                        selectedOption={marketplaceId}
                        formValueSetter={(inputText) => {
                          setMarketplaceId(capitalizeAndTrim(inputText));
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="m-auto" sm={4}>
                    <Form.Group className="mb-3" controlId="DeviceWarrantySearchForm">
                      <Form.Label className="me-4 font-weight-bold">Claim Type Code: </Form.Label>
                      <Form.Check
                        label={ClaimTypeCode.MANUFACTURER_INDUCED_DEFECT}
                        name="group1"
                        type="radio"
                        id={`inline-radio-1`}
                        checked={claimTypeCode === ClaimTypeCode.MANUFACTURER_INDUCED_DEFECT}
                        onClick={(_) => setClaimTypeCode(ClaimTypeCode.MANUFACTURER_INDUCED_DEFECT)}
                        onChange={(e) => {}}
                      />
                      <Form.Check
                        label={ClaimTypeCode.CUSTOMER_INDUCED_DEFECT}
                        name="group1"
                        type="radio"
                        id={`inline-radio-2`}
                        checked={claimTypeCode === ClaimTypeCode.CUSTOMER_INDUCED_DEFECT}
                        onClick={(_) => setClaimTypeCode(ClaimTypeCode.CUSTOMER_INDUCED_DEFECT)}
                        onChange={(e) => {}}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    {isLoading() ? (
                      <React.Fragment />
                    ) : (
                      <Button className="btn-md me-2" onClick={handleOnReset}>
                        Reset
                      </Button>
                    )}
                    <Button className="btn-md" onClick={handleQuery} disabled={!isFormValid() || isLoading()}>
                      {isLoading() ? <Spinner animation="border" size="sm" /> : <span>Search</span>}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col className="m-auto">{renderQueryResultViewer()}</Col>
        </Row>
      </Container>

      <Modal show={showBackfillLwModal} onHide={handleCloseBackfillLwModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          I ({userAlias}) know what I'm doing and want to backfill a limited warranty for dsn: ({deviceSerialNumber}),
          deviceType: ({deviceType})
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseBackfillLwModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleBackfillLw}>
            Run Backfill
          </Button>
        </Modal.Footer>
      </Modal>

      {warrantyAndWarrantyClaims.claimHistory.length > 0 && mostRecentClaim !== undefined && (
        <Modal show={showCancelClaimModal} onHide={handleCloseCancelClaimModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Confirm Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            I ({userAlias}) know what I'm doing and want to cancel the most recent claim id (
            {mostRecentClaim.warrantyClaimId}). The associated warranties will be transferred back to claim device: (
            {JSON.stringify(mostRecentClaim.claimDevice)}) from replacement device: (
            {JSON.stringify(mostRecentClaim.replacementDevice)}).
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCancelClaimModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleCancelClaim}>
              Cancel Claim
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
