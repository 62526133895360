import {fetchMarketplaces} from "../actions/MarketplaceList";
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

export interface MarketplaceSelectorProps {
    selectedOption: string;
    formValueSetter: (inputText: string) => void;
}

export default function MarketplaceSelector({ selectedOption, formValueSetter }: MarketplaceSelectorProps) {

    const marketplaceList = fetchMarketplaces();

    return (
        <Typeahead
            defaultSelected={marketplaceList.filter(option => option.obfuscated_marketplace_id === selectedOption)}
            options={marketplaceList}
            labelKey={'obfuscated_marketplace_id'}
            id={'marketplaceSelector'}
            filterBy={['country_code']}
            onInputChange={(text: string) => {
              formValueSetter(text.toUpperCase());
            }}
            onChange={(selected: any[]) => {
                const selectedMarketplace = selected[0];
                if (selected.length > 0 && marketplaceList.some(config => config.obfuscated_marketplace_id === selectedMarketplace.obfuscated_marketplace_id)) {
                    formValueSetter(selectedMarketplace.obfuscated_marketplace_id);
                }
            }}
            renderMenuItemChildren={(option: any) => {
              return (
                  <span id={option.id}>
                    {option.obfuscated_marketplace_id} : {option.country_code}
                  </span>
              );
            }}
            highlightOnlyResult={true}
            emptyLabel={'No marketplace hints available.'}
        />
    );
  }
