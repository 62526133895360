import React, { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useAppDispatch } from '../app/hooks';
import { addNotification, Severity } from '../actions/notificationsSlice';
import {RETRY_MESSAGES} from '../constants/notificationMessages';
import { invokeOncallFunction } from '../api/WsaToolsFacade';
import { QuestionCircle } from 'react-bootstrap-icons';
import './OncallOperationsPage.css';
import { OPERATION_CONFIGS } from '../constants/OncallOperations';

const RenderOperationOptions = ({
                             selectedOperation,
                             handleOperationChange,
                         }: {
    selectedOperation: string;
    handleOperationChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}) => {
    return (
        <div>
            <label htmlFor="RenderOperationOptions">Operation:</label>
            <select
                className="form-control"
                id="RenderOperationOptions"
                value={selectedOperation}
                onChange={handleOperationChange}
            >
                <option value="">Select an operation</option>
                {Object.keys(OPERATION_CONFIGS).map((operation) => (
                    <option key={operation} value={operation}>
                        {OPERATION_CONFIGS[operation].label}
                    </option>
                ))}
            </select>
        </div>
    );
};

const RenderOperationFields = ({
                             selectedOperation,
                             operationParams,
                             handleInputChange,
                         }: {
    selectedOperation: string;
    operationParams: Record<string, any>;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}) => {
    return selectedOperation ? (
        <div>
            {Object.keys(OPERATION_CONFIGS[selectedOperation].fields).map((field) => (
                <Form.Group
                    key={field}
                    className="inputFieldGroup"
                    controlId={`InputField-${field}`}
                >
                    <label htmlFor={`InputField-${field}`}>{OPERATION_CONFIGS[selectedOperation].fields[field]}:</label>
                    <input
                        type="text"
                        className="form-control"
                        name={field}
                        value={operationParams[field] || ''}
                        onChange={handleInputChange}
                    />
                </Form.Group>
            ))}
        </div>
    ) : null;
};

const CustomPayloadInput = ({
                                selectedOperation,
                                operationParams,
                                handleInputChange,
                            }: {
    selectedOperation: string;
    operationParams: Record<string, any>;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}) => {
    return selectedOperation === 'CustomPayload' ? (
        <div className="customInputGroup">
            <Form.Group
                className="inputFieldGroup"
                controlId={`InputField-payload`}
            >
                <label htmlFor={`InputField-payload`}>Payload:</label>
                <textarea
                    className={`form-control payloadTextArea`}
                    name="payload"
                    value={operationParams.payload || ''}
                    onChange={handleInputChange}
                />
            </Form.Group>
        </div>
    ) : null;
};

const SubmitButton = ({
                          isProcessingRequest,
                          handleSubmit,
                          selectedOperation,
                          operationParams,
                      }: {
    isProcessingRequest: boolean;
    handleSubmit: () => void;
    selectedOperation: string;
    operationParams: Record<string, any>;
}) => {
    return (
        <div className="submitButtonContainer">
            {isProcessingRequest ? (
                <Spinner animation="border" size="sm" />
            ) : (
                <Button
                    className="btn btn-md"
                    onClick={handleSubmit}
                    disabled={
                        !selectedOperation ||
                        Object.keys(OPERATION_CONFIGS[selectedOperation].fields).some((field) => !operationParams[field])
                    }
                >
                    Submit
                </Button>
            )}
        </div>
    );
};

const ResultDisplay = ({ displayResult }: { displayResult: any }) => {
    if (displayResult === null) {
        return null;
    }
    return (
        <div className="addPadding">
            <div className="resultDisplay">
                <pre>{JSON.stringify(displayResult, null, 2)}</pre>
            </div>
        </div>
    );
};

const OncallOperationsPage = () => {
    const dispatch = useAppDispatch();

    const [selectedOperation, setSelectedOperation] = useState('');
    const [operationParams, setOperationParams] = useState<Record<string, any>>({});
    const [isProcessingRequest, setIsProcessingRequest] = useState(false);
    const [displayResult, setDisplayResult] = useState<any>(null);

    const ONCALL_OPERATIONS_PAGE_WIKI = 'https://w.amazon.com/bin/view/WarrantyServices/Oncall/WSAOncallOperationsUtilityLambda';

    const handleOperationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOperation(e.target.value);
        setOperationParams({});
        setDisplayResult(null);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setOperationParams({ ...operationParams, [e.target.name]: e.target.value });
    };

    const handleSubmit = () => {
        setIsProcessingRequest(true);

        let invokeOncallOperation;
        if (selectedOperation === 'CustomPayload') {
            const payload = JSON.parse(operationParams.payload);
            invokeOncallOperation = invokeOncallFunction(payload.OPERATION, payload);
        } else {
            invokeOncallOperation = invokeOncallFunction(selectedOperation, operationParams);
        }

        invokeOncallOperation
            .then((response) => {
                if (response.status === 200) {
                    dispatch(addNotification({ message: response.data.message, severity: Severity.SUCCESS }));
                    setDisplayResult(response.data);
                } else {
                    dispatch(addNotification({ message: RETRY_MESSAGES.DEFAULT, severity: Severity.ERROR }));
                }
            })
            .catch((error) => {
                console.error(error);
                dispatch(addNotification({ message: RETRY_MESSAGES.DEFAULT, severity: Severity.ERROR }));
            })
            .finally(() => {
                setIsProcessingRequest(false);
            });
    };

        return (
            <div>
                <div>
                    <h2 className="headerFormat">
                        ONCALL OPERATIONS &nbsp;
                        <a href={ONCALL_OPERATIONS_PAGE_WIKI} target="blank" className="headerFormat">
                            <QuestionCircle />
                        </a>
                    </h2>
                </div>
                <div className="formContainer">
                    <form>
                        <RenderOperationOptions
                            selectedOperation={selectedOperation}
                            handleOperationChange={handleOperationChange}
                        />
                        <RenderOperationFields
                            selectedOperation={selectedOperation}
                            operationParams={operationParams}
                            handleInputChange={handleInputChange}
                        />
                        <CustomPayloadInput
                            selectedOperation={selectedOperation}
                            operationParams={operationParams}
                            handleInputChange={handleInputChange}
                        />
                        <SubmitButton
                            isProcessingRequest={isProcessingRequest}
                            handleSubmit={handleSubmit}
                            selectedOperation={selectedOperation}
                            operationParams={operationParams}
                        />
                    </form>
                </div>
                <ResultDisplay displayResult={displayResult} />
            </div>
        );
    };

    export default OncallOperationsPage;
