import store from '../app/store';
import { addNotification, Severity } from '../actions/notificationsSlice';
import { AxiosResponseHeaders } from 'axios';
import {DORMANT_WARRANTY_EXCEPTION_NAME_STRING} from "./utils";

export enum RETRY_MESSAGES {
  DEFAULT = 'Something went wrong. Please wait 30 seconds and try again.',
  TIMEOUT = 'Request timed out. Please wait 30 seconds and try again.'
}

export enum SUCCESS_MESSAGES {
  BACKFILL_LW = 'Successfully backfilled limited warranty.',
  TRANSFER_LW_FOR_CANCELED_CLAIM = 'Successfully canceled claim and transferred limited warranty back to the claim device.',
  BIND_EW = 'Successfully bound device to extended warranty.',
  DORMANT_WARRANTY = "The requested device's warranty is expired for some time and cannot be accessed. Claim options may be available."
}

export enum ERROR_MESSAGES {
  UNAUTHORIZED = 'You are not authorized to perform the request.'
}

export const dispatchRequestErrorNotification = (status: number, message: string, headers: AxiosResponseHeaders) => {
  console.log(headers)
  switch (status) {
    case 403:
      store.dispatch(addNotification({ message: ERROR_MESSAGES.UNAUTHORIZED, severity: Severity.ERROR }));
      return;
    case 504:
      store.dispatch(addNotification({ message: RETRY_MESSAGES.TIMEOUT, severity: Severity.ERROR }));
      return;
    default:
      if (headers["x-amzn-errortype"] === DORMANT_WARRANTY_EXCEPTION_NAME_STRING) {
        store.dispatch(addNotification({ message: SUCCESS_MESSAGES.DORMANT_WARRANTY, severity: Severity.ERROR}))
      }
      else if (!message) {
        store.dispatch(addNotification({ message: RETRY_MESSAGES.DEFAULT, severity: Severity.ERROR }));
      }
      else {
        store.dispatch(addNotification({ message, severity: Severity.ERROR }));
      }
      return;
  }
};
