import { Container, Row } from 'react-bootstrap';

export default function HomePage() {
  return (
    <Container className="margin-auto">
      <Row>
        <h1>Welcome to Warranty Services by Amazon Tools</h1>
      </Row>

      <Row className="d-flex justify-content-center align-items-center m-3">
        <span>
          WSA Wiki:&nbsp;
          <a href="https://w.amazon.com/bin/view/WarrantyServices/" target="_blank" rel="noopener noreferrer">
            https://w.amazon.com/bin/view/WarrantyServices
          </a>
        </span>
        <span>
          Cut a SIM:&nbsp;
          <a
            href="https://t.corp.amazon.com/create/options?ticket_type=Regular+Ticket&category=Digital%20Media%20Technology&type=Kindle%20Device%20Sales&item=WarrantyServices"
            target="_blank"
            rel="noopener noreferrer"
          >
            Digital Media Technology/Kindle Device Sales/WarrantyServices
          </a>
        </span>
      </Row>
    </Container>
  );
}
