import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { getUserAlias } from '../auth/MidwayJwtToken';

export interface UserSlice {
  userAlias: string;
}

const initialState: UserSlice = {
  userAlias: ''
};

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserAlias(state, action: PayloadAction<string>) {
      return { ...state, userAlias: action.payload };
    }
  }
});

export const setUserAliasFromToken = () => async (dispatch: Dispatch) => {
  dispatch(userSlice.actions.setUserAlias(await getUserAlias()));
};

export default userSlice.reducer;
