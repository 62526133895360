import React from 'react';
import './App.css';
import NavBar from './components/NavBar';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { setUserAliasFromToken } from './actions/userSlice';
import { Alert } from 'react-bootstrap';
import { removeNotification } from './actions/notificationsSlice';

function App() {
  const dispatch = useAppDispatch();
  dispatch(setUserAliasFromToken());
  const { notifications } =
    useAppSelector((state) => state.notifications);

  const renderNotifications = () => {
    return (<div className="sticky-top notificationsContainer">
      {notifications.map(notification =>
        <Alert variant={notification.severity}
               className='m-0 pb-0'
               onClose={() =>
                 dispatch(removeNotification(notification))
               }
               dismissible
               key={notification.id}
        >
          <p>{notification.message}</p>
        </Alert>
      )}
    </div>);
  };

  return (
    <div className='App'>
      <NavBar />
      {renderNotifications()}
      <div className='mt-5 mb-5'>
        <Outlet />
      </div>
    </div>
  );
}

export default App;
