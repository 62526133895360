import React from 'react';

interface FormattedTextProps {
  data: object;
}

export default function JSONText({ data }: FormattedTextProps) {
  return (
    <pre className="m-auto text-start">
      <span>{JSON.stringify(data, null, 2)}</span>
    </pre>
  );
}
