import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum Severity {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'danger'
}

export interface AddNotification {
  message: string;
  severity: Severity;
}

export interface Notification {
  id: number;
  message: string;
  severity: Severity;
}

export interface NotificationSliceState {
  notifications: Notification[];
}

const initialState: NotificationSliceState = {
  notifications: []
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<AddNotification>) {
      const newNotification = {
        id: new Date().getMilliseconds(),
        message: action.payload.message,
        severity: action.payload.severity
      };
      return { notifications: [...state.notifications, newNotification] };
    },
    removeNotification(state, action: PayloadAction<Notification>) {
      return { notifications: state.notifications.filter((notification) => notification.id !== action.payload.id) };
    },
    clearNotifications() {
      return { notifications: [] };
    }
  }
});

export const { addNotification, removeNotification, clearNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;
