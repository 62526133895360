import { combineReducers } from '@reduxjs/toolkit';
import userSlice from '../actions/userSlice';
import notificationsSlice from '../actions/notificationsSlice';

const rootReducer = combineReducers({
  user: userSlice,
  notifications: notificationsSlice
});

export default rootReducer;
